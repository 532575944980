import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({});

interface IProps extends WithStyles<typeof styles> {}

const NotFound: React.FC<IProps> = props => {

    return (

        <div>
        
            nothing here, sorry.

        </div>
    );

}

export default withStyles(styles)(NotFound);

