import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      overflowX: 'auto',
    },
  });

interface Props extends WithStyles<typeof styles> {}

const Home: React.FC<Props> = props => {

    // const { classes } = props;

    return (

    <div style={{textAlign:"center"}}>
      
        build the 4wd(s) of your dreams.

        <br></br><br/>
        
        comprehensive database of vendors and parts. ensured fitment for each vehicle.

        <br></br><br/>

        create and save multiple configurations. share them with friends.

        <br></br><br/>

        participate in and learn from a community of like-minded builders.

        <br/><br/><br/>
        
        coming soon.

    </div>

  );

}

export default withStyles(styles)(Home);

