import React from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';

import { useHistory } from 'react-router-dom'

import './App.css';

import Routes from "./routes";
import MainListItems from './menuItems';

import useStyles from './theme'

// import useGlobal from './store/store';

const App: React.FC = () => {

    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()

    const [open, setOpen] = React.useState(false)

    // const [globalState, globalActions] = useGlobal()

    function handleDrawerOpen() {
        setOpen(true);
    }

    function handleDrawerClose() {
        setOpen(false);
    }

    return (

        <div className={classes.root}>

            <CssBaseline />

            {/* <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
                })}
            >
 
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography style={{width:"100%"}} variant="h6" noWrap>
                        APP NAME
                    </Typography>
                    <div className={classes.barRight}>
                        <Button
                            onClick={() => history.push("/login")}
                            variant="contained"
                            component="span"
                            className={classes.menuButton}
                        >
                            Login/Sign Up
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                open={open}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />

                <MainListItems />

            </Drawer> */}

            <main className={classes.content}>
                <div className={classes.toolbar} />
                    <Routes />
            </main>
        </div>

  );

}

export default App;

