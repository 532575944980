import React from "react"
import { Route, Switch } from "react-router-dom"
import Home from "./pages/home"
// import About from "./pages/about"
// import Contact from "./pages/contact"
import NotFound from "./pages/notFound"

export default () =>
    <Switch>
        <Route path="/" exact component={Home} />
        {/* <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} /> */}
        <Route component={NotFound} />
    </Switch>

